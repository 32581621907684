import { VendorI } from '@/types/cyclone/models';
import { NextSeo } from 'next-seo';
import React, { FunctionComponent } from 'react';

export type Props = {
  vendor: VendorI;
};

const SEO: FunctionComponent<Props> = ({ vendor }) => {
  const title = `${vendor?.vendor_storefront?.name} - ${vendor?.professional_title} | Ágora`;
  const description =
    vendor?.plan_name === 'professional'
      ? `Consulta nuestra disponibilidad en tiempo real y reserva tu servicio.`
      : `Consulta nuestros próximos eventos y reserva tus entradas online.`;
  const url = `https://agora.red/${vendor?.username}`;

  return (
    <NextSeo
      title={title}
      description={description}
      canonical={url}
      openGraph={{
        url,
        title,
        description,
        type: 'profile',
        profile: {
          firstName: vendor?.user?.first_name,
          lastName: vendor?.user?.last_name,
          username: vendor?.username
        },
        images: [
          {
            url: `${vendor?.user?.avatar_url}.webp`,
            alt: `${vendor?.username}'s imagen de portada`
          }
        ],
        site_name: title
      }}
      additionalMetaTags={[
        {
          property: 'keywords',
          content: 'servicios, profesional, Ágora'
        }
      ]}
      twitter={{
        cardType: 'summary_large_image',
        site: '@agora_red',
        handle: '@agora_red'
      }}
    />
  );
};

export default SEO;
