import { AgoraThemeE, AgoraThemeI, RoundedE, TypeButtonsE, VendorI } from '@/types/cyclone/models';

const API_URL = process.env.NEXT_PUBLIC_CYCLONE_API_URL || 'https://cyclone.agora.red';

//
// eslint-disable-next-line @next/next/no-typos
export const _generateStaticParams = async () => {
  try {
    const res = await fetch(`${API_URL}/vendors/published`);
    if (!res.ok) {
      throw new Error(`API responded with status ${res.status}`);
    }
    const _vendors: VendorI[] = await res.json();
    const vendors = _vendors.filter((vendor) => vendor.username !== null);

    return vendors.map((vendor) => ({
      vendor_username: vendor.username.toString()
    }));
  } catch (error) {
    console.error('Failed to fetch paths:', error);
    return [];
  }
};

export const _generateStaticParamsForServices = async () => {
  try {
    const res = await fetch(`${API_URL}/vendors/published?type=services`);
    if (!res.ok) {
      throw new Error(`API responded with status ${res.status}`);
    }
    const _vendors: VendorI[] = await res.json();
    const vendors = _vendors.filter((vendor) => vendor.username !== null);

    return vendors.map((vendor) => ({
      vendor_username: vendor.username.toString()
    }));
  } catch (error) {
    console.error('Failed to fetch paths:', error);
    return [];
  }
};

export const _generateStaticParamsForDigitalContents = async () => {
  try {
    const res = await fetch(`${API_URL}/vendors/published?type=digital_contents`);
    if (!res.ok) {
      throw new Error(`API responded with status ${res.status}`);
    }
    const _vendors: VendorI[] = await res.json();
    const vendors = _vendors.filter((vendor) => vendor.username !== null);

    return vendors.map((vendor) => ({
      vendor_username: vendor.username.toString()
    }));
  } catch (error) {
    console.error('Failed to fetch paths:', error);
    return [];
  }
};

export const _generateStaticParamsForUniqueEvents = async () => {
  try {
    const res = await fetch(`${API_URL}/vendors/published?type=unique_events`);
    if (!res.ok) {
      throw new Error(`API responded with status ${res.status}`);
    }
    const _vendors: VendorI[] = await res.json();
    const vendors = _vendors.filter((vendor) => vendor.username !== null);

    return vendors.map((vendor) => ({
      vendor_username: vendor.username.toString()
    }));
  } catch (error) {
    console.error('Failed to fetch paths:', error);
    return [];
  }
};

export const _generateStaticParamsForSubscriptions = async () => {
  try {
    const res = await fetch(`${API_URL}/vendors/published?type=subscriptions`);
    if (!res.ok) {
      throw new Error(`API responded with status ${res.status}`);
    }
    const _vendors: VendorI[] = await res.json();
    const vendors = _vendors.filter((vendor) => vendor.username !== null);

    return vendors.map((vendor) => ({
      vendor_username: vendor.username.toString()
    }));
  } catch (error) {
    console.error('Failed to fetch paths:', error);
    return [];
  }
};

export const _generateStaticParamsForBancar = async () => {
  try {
    const res = await fetch(`${API_URL}/vendors/published?type=bancar`);
    if (!res.ok) {
      throw new Error(`API responded with status ${res.status}`);
    }
    const _vendors: VendorI[] = await res.json();
    const vendors = _vendors.filter((vendor) => vendor.username !== null);

    return vendors.map((vendor) => ({
      vendor_username: vendor.username.toString()
    }));
  } catch (error) {
    console.error('Failed to fetch paths:', error);
    return [];
  }
};

export const _generateStaticParamsForBio = async () => {
  try {
    const res = await fetch(`${API_URL}/vendors/published?type=bio`);
    if (!res.ok) {
      throw new Error(`API responded with status ${res.status}`);
    }
    const _vendors: VendorI[] = await res.json();
    const vendors = _vendors.filter((vendor) => vendor.username !== null);

    return vendors.map((vendor) => ({
      vendor_username: vendor.username.toString()
    }));
  } catch (error) {
    console.error('Failed to fetch paths:', error);
    return [];
  }
};

export const getTheme = (vendor?: VendorI): AgoraThemeI => {
  if (vendor && vendor.vendor_storefront) {
    return {
      name: vendor.vendor_storefront.template_name,
      colors: {
        title: vendor.vendor_storefront.title_color,
        background: vendor.vendor_storefront.background_color,
        text: vendor.vendor_storefront.text_color,
        button: vendor.vendor_storefront.button_color,
        shadow: vendor.vendor_storefront.shadow_color
      },
      pattern_type: vendor.vendor_storefront.pattern_type
    };
  }
  return {
    name: AgoraThemeE.CLEAN,
    colors: {
      title: '#212121',
      background: '#ffffff',
      text: '#FAFAFA',
      button: '#212121FF',
      shadow: '#212121'
    },
    pattern_type: null
  };
};

const getBorderRadius = (rounded: RoundedE, isSm: boolean) => {
  switch (rounded) {
    case RoundedE.ROUNDED_0:
      return 0;
    case RoundedE.ROUNDED_10:
      return isSm ? 10 : 6;
    case RoundedE.ROUNDED_20:
    case RoundedE.ROUNDED_30:
      return isSm ? 30 : 25;
    default:
      return 0;
  }
};

export const getButtonStyles = (vendor: VendorI, vendorTheme: any, isSm: boolean) => {
  if (vendor && vendor.vendor_storefront) {
    const rounded = vendor.vendor_storefront.border_radius_px || RoundedE.ROUNDED_0;
    const typeButton = vendor.vendor_storefront.button_type || TypeButtonsE.SOLID;

    return {
      borderRadius: getBorderRadius(rounded, isSm),
      color: vendorTheme.colors.text,
      ...(typeButton === TypeButtonsE.SOLID && { background: vendorTheme.colors.button }),
      ...(typeButton === TypeButtonsE.OUTLINE && { border: `2px solid ${vendorTheme.colors.button}` }),
      ...(typeButton === TypeButtonsE.DARK_SHADOW && {
        border: `2px solid ${vendorTheme.colors.button}`,
        boxShadow: `4px 4px 0px ${vendorTheme.colors.shadow}`,
        background: vendorTheme.colors.button
      }),
      ...(typeButton === TypeButtonsE.SOFT_SHADOW && {
        boxShadow: `3px 3px 13px -6px ${vendorTheme.colors.shadow}`,
        background: vendorTheme.colors.button
      })
    };
  } else return {};
};
