'use client';

import { Column } from '@/components/Column';
import { useSuccessSplash } from '@/contexts';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { getTheme } from './utils';
import { useVendorContext } from './vendorContext';
import SEO from './components/SEO';
import Header from './components/Header';
import Social from './components/Social';
import Navigation from './components/Navigation';

export const VendorClient = () => {
  const { showSuccessSplash } = useSuccessSplash();
  const params = useSearchParams();
  const statusParam = params?.get('status') as string;
  const { vendor } = useVendorContext();

  const displaySuccessSplash = () => {
    showSuccessSplash({
      message: '¡Reserva confirmada!',
      mainButtonText: 'Volver',
      mainButtonAction: () => {
        return;
      }
    });
  };

  useEffect(() => {
    if (statusParam === 'approved') {
      displaySuccessSplash();
    }
  }, [statusParam]); // eslint-disable-line

  const vendorTheme = getTheme(vendor);

  const vendorLinks = vendor?.vendor_links?.filter((link) => !link.is_hidden);

  return (
    <>
      <SEO vendor={vendor!} />
      <div style={{ minHeight: 'calc(100vh - 70px)' }}>
        <Column>
          <Header themeType={vendorTheme} vendor={vendor!} />
          {vendor?.vendor_social_apps && (
            <Social themeType={vendorTheme} socialMedias={vendor.vendor_social_apps} />
          )}
          {vendorLinks && (
            <Navigation
              themeType={vendorTheme}
              links={vendorLinks}
              username={vendor!.username || ''}
              vendor={vendor!}
            />
          )}
        </Column>
      </div>
    </>
  );
};
